const ittybit = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 2H11V4H9V20H11V22H17V20H19V18H21V14H19V12H17V10H13V2ZM17 14V18H13V14H17Z"
      fill="#e5e0ff" // text-brand-50
    />
    <path
      d="M3 10V22H7V10H3Z"
      fill="#e5e0ff" // text-brand-50
    />
    <path
      d="M7 2H3V6H7V2Z"
      fill="#ae81ff" // text-brand-purple
    />
  </svg>
);
const account = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2H9V3.99994H7.00024V9.99994H9.00024V4H15V2ZM15 10H9V12H15V10ZM15.0002 3.99994H17.0002V9.99994H15.0002V3.99994ZM4 15.9999H6V14H18V16H6V20H18.0002V15.9999H20.0002V21.9999H20V22H4V21.9999V20V15.9999Z"
      fill="currentColor"
    />
  </svg>
);
const add = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 4H11V11H4V13H11V20H13V13H20V11H13V4Z"
      // d="M5 3H19V5H5V3ZM5 19H3V5H5V19ZM19 19H21V5H19V19ZM19 19H5V21H19V19ZM13 7H11V11H7V13H11V17H13V13H17V11H13V7Z"
      fill="currentColor"
    />
  </svg>
);
const arrow_down = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 4H13V16H15V18H13V20H11V18H9V16H11V4ZM7 14V16H9V14H7ZM7 14V12H5V14H7ZM17 14V16H15V14H17ZM17 14V12H19V14H17Z"
      fill="currentColor"
    />
  </svg>
);
const arrow_left = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 11L20 13L8 13L8 15L6 15L6 13L4 13L4 11L6 11L6 9L8 9L8 11L20 11ZM10 7L8 7L8 9L10 9L10 7ZM10 7L12 7L12 5L10 5L10 7ZM10 17L8 17L8 15L10 15L10 17ZM10 17L12 17L12 19L10 19L10 17Z"
      fill="currentColor"
    />
  </svg>
);
const arrow_right = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 11L4 13L16 13L16 15L18 15L18 13L20 13L20 11L18 11L18 9L16 9L16 11L4 11ZM14 7L16 7L16 9L14 9L14 7ZM14 7L12 7L12 5L14 5L14 7ZM14 17L16 17L16 15L14 15L14 17ZM14 17L12 17L12 19L14 19L14 17Z"
      fill="currentColor"
    />
  </svg>
);
const arrow_up = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 20H13V8H15V6H13V4H11V6H9V8H11V20ZM7 10V8H9V10H7ZM7 10V12H5V10H7ZM17 10V8H15V10H17ZM17 10V12H19V10H17Z"
      fill="currentColor"
    />
  </svg>
);
const attach = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 17L5 5L7 5L7 17L5 17ZM7 17L9 17L9 19L7 19L7 17ZM13 5L7 5L7 3L13 3L13 5ZM13 15L15 15L15 5L13 5L13 15ZM11 15L13 15L13 17L11 17L11 15ZM11 15L11 7L9 7L9 15L11 15ZM17 17L17 3L19 3L19 17L17 17ZM9 21L9 19L15 19L15 21L9 21ZM15 19L15 17L17 17L17 19L15 19Z"
      fill="currentColor"
    />
  </svg>
);
const audio = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 3H8V13H4V15H2V19H4V21H8V19H10V7H20V13H16V15H14V19H16V21H20V19H22V3ZM20 19H16V15H20V19ZM8 19H4V15H8V19Z"
      fill="currentColor"
    />
  </svg>
);
const automation = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1H14V9H22V11V13H20V11H14H12V9V5H10V3H12V1ZM8 7V5H10V7H8ZM6 9V7H8V9H6ZM4 11V9H6V11H4ZM14 19V21H12V23H10V15H2V13V11H4V13H10H12V15V19H14ZM16 17V19H14V17H16ZM18 15V17H16V15H18ZM18 15H20V13H18V15Z"
      fill="currentColor"
    />
  </svg>
);
const back = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2H14V4H12V6H10V8H8V10H6V12V14H8V16H10V18H12V20H14V22H16V20H18V18H16V16H14V14H12V12V10H14V8H16V6H18V4H16V2Z"
      fill="currentColor"
    />
  </svg>
);
const cdn = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 1H15V3H13V5H11V3H9V1ZM9 5V3H7H5V5H3V7H1V9V15V17H3V19H5V21H7H9V23H15V21H17H19V19H21V17H23V15V9V7H21V5H19V3H17H15V5H13V7H15V9H13H11V11H9V13H11V15V17H13V19H15V21H9V19H7H5V17H7V15V13H5V11H7V9H9V7H11V5H9ZM9 5V7H7V9H5V11H3V9V7H5V5H7H9ZM3 15V17H5V15V13H3V15ZM15 17H13V15V13H11V11H13H15V9H17V7H15V5H17H19V7H21V9H19V11H17V13V15H15V17ZM15 17V19H17H19V17H21V15V11H19V13V15H17V17H15Z"
      fill="currentColor"
    />
  </svg>
);
const chapters = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 18V4V2H19H7V4H19V18H21ZM5 6H3V8V22H5H7V20H9V18H11V20H13V22H15H17V8V6H15H5ZM13 20V18H11V16H9V18H7V20H5V8H15V20H13Z"
      fill="currentColor"
    />
  </svg>
);
const check = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 4H18V6H16V8H14V10H12V12H10V14H8V12H6V10H4V12H2V14H4V16H6V18H8V20H10V18H12V16H14V14H16V12H18V10H20V8H22V6H20V4Z"
      fill="currentColor"
    />
  </svg>
);
const chevron_left = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 19L17 21L15 21L15 19L13 19L13 17L11 17L11 15L9 15L9 13L7 13L7 11L9 11L9 9L11 9L11 7L13 7L13 5L15 5L15 3L17 3L17 5L15 5L15 7L13 7L13 9L11 9L11 11L9 11L9 13L11 13L11 15L13 15L13 17L15 17L15 19L17 19Z"
      fill="currentColor"
    />
  </svg>
);
const chevron_right = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5L7 3L9 3L9 5L11 5L11 7L13 7L13 9L15 9L15 11L17 11L17 13L15 13L15 15L13 15L13 17L11 17L11 19L9 19L9 21L7 21L7 19L9 19L9 17L11 17L11 15L13 15L13 13L15 13L15 11L13 11L13 9L11 9L11 7L9 7L9 5L7 5Z"
      fill="currentColor"
    />
  </svg>
);
const click = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 1H11V5H9V1ZM13 7V9H9V13H7V9V7H9H13ZM11 17H9V13H11V17ZM15 19V21H13H11V19V17H13V19H15ZM17 17H15V19H17V21H19V23H21V21H23V19H21V17H19V15H21V13V11H19H17V9H13V11H17V13H19V15H17V17ZM19 19H17V17H19V19ZM19 19H21V21H19V19ZM5 9H1V11H5V9ZM6 3H4V1H2V3H4V5H6V3Z"
      fill="currentColor"
    />
  </svg>
);
const close = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 5H17V7H15V9H13V11H11V9H9V7H7V5H5V7H7V9H9V11H11V13H9V15H7V17H5V19H7V17H9V15H11V13H13V15H15V17H17V19H19V17H17V15H15V13H13V11H15V9H17V7H19V5Z"
      fill="currentColor"
    />
  </svg>
);
const code = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99951 4.99995H9.99951V6.99995H7.99951V4.99995Z"
      fill="currentColor"
    />
    <path
      d="M5.99951 8.99995V6.99995H7.99951V8.99995H5.99951Z"
      fill="currentColor"
    />
    <path d="M3.99951 11V8.99995H5.99951V11H3.99951Z" fill="currentColor" />
    <path d="M3.99951 13H1.99951V11H3.99951V13Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99951 11H3.99951V13V15H5.99951V17H7.99951V19H9.99951L10 15H8V13H6L5.99951 11Z"
      fill="currentColor"
    />
    <path
      d="M7.99951 8.99995H5.99951V11L8 11L7.99951 8.99995Z"
      fill="currentColor"
    />
    <path
      d="M7.99951 8.99995V6.99995H9.99951L10 9L7.99951 8.99995Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9995 4.99995H13.9995V6.99995L14 9H16V11H18V13L16 13V15L14 15L13.9995 19H15.9995V17L17.9995 17V15L19.9995 15V13L21.9995 13V11H19.9995V8.99995H17.9995V6.99995H15.9995V4.99995Z"
      fill="currentColor"
    />
  </svg>
);
const compress = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 6H15V8H17V10H15V12H19H21V10V6H19V8H17V6H15V4H13V2H11V4H13V6ZM3 22V6H5H9H11L11 22H9H5V20H9L9 8H5L5 22H3ZM17 20V16H15V20H17ZM13 22H15H17H19V14H17H15H13V22Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
const copy = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 3H10V5H18V15H20V5H18V3ZM6 9H4V19H6V21H14V19H16V7H14H6V9ZM14 9V19H6V9H14Z"
      fill="currentColor"
    />
  </svg>
);
const cross = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3H5V5H3V7H5V9H7V11H9V13H7V15H5V17H3V19H5V21H7V19H9V17H11V15H13V17H15V19H17V21H19V19H21V17H19V15H17V13H15V11H17V9H19V7H21V5H19V3H17V5H15V7H13V9H11V7H9V5H7V3Z"
      fill="currentColor"
    />
  </svg>
);
const darkmode = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H22V2H24V4H22V6H20V4H18V2H20V0ZM8 4H16V6H14V8H12V6H8V4ZM6 8V6H8V8H6ZM6 16H4V8H6V16ZM8 18H6V16H8V18ZM16 18V20H8V18H16ZM18 16V18H16V16H18ZM16 12V10H18V8H20V16H18V12H16ZM12 12H16V14H12V12ZM12 12V8H10V12H12ZM4 18H2V20H0V22H2V24H4V22H6V20H4V18Z"
      fill="currentColor"
    />
  </svg>
);
const dashboard = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3H11V5V11V13H3V11V5H5V11H9V5H5V3ZM19 7H15V5H19V7ZM21 7V5H19V3H13V5V7V9H21V7ZM9 19H5V17H9V19ZM11 21V19V17V15H3V17V19H5V21H11ZM19 19H15V13H19V19ZM19 21V19H21V13V11H13V13V19V21H19Z"
      fill="currentColor"
    />
  </svg>
);
const description = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 7H11V9V15V17H1V15V9V7ZM9 15V9H3V15H9ZM23 7H13V9H23V7ZM13 11H23V13H13V11ZM19 15H13V17H19V15Z"
      fill="currentColor"
    />
  </svg>
);
const docs = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3H11V5H3V17H11V5H13V17H21V5H13V3H21H23V5V17V19H21H13V21H11V19H3H1V17V5V3H3ZM19 10H15V12H19V10ZM15 7H19V9H15V7ZM17 13H15V15H17V13Z"
      fill="currentColor"
    />
  </svg>
);
const domains = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2H9V4H7H5V6H3V8H1V10V16H3V18H5V20H7H9V22H15V20H17H19V18H21V16H23V10V8H21V6H19V4H17H15V2ZM15 6V4H13V6H11V4H9V6H7H5V8H3V10V12H5V14H3V16H5V18H7H9V20H15V18H17H19V16H21V12H19V10H21V8H19V6H17H15ZM15 8V6H13V8H15ZM15 10H17V8H15V10ZM11 12H13H15V10H13H11V12ZM11 14V12H9V14H11ZM13 16V14H11V16H13ZM15 16V18H13V16H15ZM17 14H19V12H17V14ZM17 14H15V16H17V14ZM5 16H7V14H5V16ZM7 10H5V12H7V10ZM9 8V6H11V8H9ZM9 8H7V10H9V8Z"
      fill="currentColor"
    />
  </svg>
);
const dot = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6H14V8H16V10H18V12V14H16V16H14V18H12H10V16H8V14H6V12V10H8V8H10V6H12Z"
      fill="currentColor"
    />
  </svg>
);
const edit = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 2H16V4H14V6H12V8H10V10H8V12H6V14H4V16H2V20V22H4H8V20H10V18H12V16H14V14H16V12H18V10H20V8H22V6H20V4H18V2ZM18 10H16V12H14V14H12V16H10V18H8V16H6V14H8V12H10V10H12V8H14V6H16V8H18V10ZM6 16H4V20H8V18H6V16Z"
      fill="currentColor"
    />
  </svg>
);
const ellipsis = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 9H7V15H1V9ZM3 11V13H5V11H3ZM9 9H15V15H9V9ZM11 11V13H13V11H11ZM17 9H23V15H17V9ZM19 11V13H21V11H19Z"
      fill="currentColor"
    />
  </svg>
);
const education = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 3H13V5H11V3ZM7 7V5H11V7H7ZM3 9V7H7V9H3ZM3 11H1V9H3V11ZM7 13V11H3V13H5V17H7V19H9V21H15V19H17V17H19V13H21V15V17H23V15V13H21V11H23V9H21V7H17V5H13V7H17V9H21V11H17V13H13V15H11V13H7ZM7 15V17H9V19H15V17H17V15H13V17H11V15H7Z"
      fill="currentColor"
    />
  </svg>
);
const email = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 3H16V5H14H12H10H8V3H10H12H14ZM6 7V5H8V7H6ZM6 17H4V15V13V11V9V7H6V9V11V13V15V17ZM8 19H6V17H8V19ZM16 19H14H12H10H8V21H10H12H14H16H18V19H16ZM18 7H16V5H18V7ZM18 15V13V11V9V7H20V9V11V13V15H18ZM10 15V17H12H14H16H18V15H16V13V11V9H14V7H12H10V9H8V11V13V15H10ZM10 15V13V11V9H12H14V11V13V15H12H10Z"
      fill="currentColor"
    />
  </svg>
);
const error = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3H19V5H5V3ZM5 19H3V5H5V19ZM19 19V21H5V19H19ZM19 19H21V5H19V19ZM11 9H9V7H7V9H9V11H11V13H9V15H7V17H9V15H11V13H13V15H15V17H17V15H15V13H13V11H15V9H17V7H15V9H13V11H11V9Z"
      fill="currentColor"
    />
  </svg>
);
const expand = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 7H3V9H5V11H7V13H9V15H11V17H13V15H15V13H17V11H19V9H21V7H19V9H17V11H15V13H13V15H11V13H9V11H7V9H5V7Z"
      fill="currentColor"
    />
  </svg>
);
const expand_up = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 17H3V15H5V13H7V11H9V9H11V7H13V9H15V11H17V13H19V15H21V17H19V15H17V13H15V11H13V9H11V11H9V13H7V15H5V17Z"
      fill="currentColor"
    />
  </svg>
);
const faces = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2H8V4H6V6H4V8V10H2V14H4V18H6V20H8V22H16V20H18V18H20V14H22V10H20V8V6H18V4H16V2ZM20 10V14H18V18H16V20H8V18H6V14H4V10H6V8H8V6H16V8H18V10H20ZM10 16H14V18H10V16ZM10 16H8V14H10V16ZM14 16H16V14H14V16ZM10 10H8V12H10V10ZM14 10H16V12H14V10Z"
      fill="currentColor"
    />
  </svg>
);
const file = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 2H3V22H15V20H17V18H19V16H21V2ZM17 16V18H15V16H17ZM5 20V4H19V14H13V20H5ZM7 6H17V8H7V6ZM17 10H7V12H17V10ZM7 14H11V16H7V14Z"
      fill="currentColor"
    />
  </svg>
);
const files = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 21H17V19H19V21ZM21 19V21H19V23H17H7V5H21V19ZM5 19H3V3V1H17V3H5V19ZM11 9H17V11H11V9ZM17 13H11V15H17V13ZM11 17H13V19H11V17ZM9 21V7H19V17H15V21H9Z"
      fill="currentColor"
    />
  </svg>
);
const filter = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M4 5H20V7H4V5Z" fill="currentColor" />
    <path d="M6 9H18V11H6V9Z" fill="currentColor" />
    <path d="M8 13H16V15H8V13Z" fill="currentColor" />
    <path d="M10 17H14V19H10V17Z" fill="currentColor" />
  </svg>
);
const folder = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4H12V6H20H22V8L22 18V20L20 20H4L2 20V18V6V4H4ZM20 8H10V6H4V18H20V8Z"
      fill="currentColor"
    />
  </svg>
);
const guides = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 18V4V2H19H7V4H19V18H21ZM5 6H3V8V22H5H7V20H9V18H11V20H13V22H15H17V8V6H15H5ZM13 20V18H11V16H9V18H7V20H5V8H15V20H13Z"
      fill="currentColor"
    />
  </svg>
);
const help = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 2H6V4H4V6H2V18H4V20H6V22H18V20H20V18H22V6H20V4H18V2ZM16 8V6H14V4H18V6H20V12H18V8H16ZM16 16V8H8V6H12V4H6V6H4V10H6V8H8V16H6V12H4V18H6V20H10V18H8V16H16ZM20 14V18H18V20H12V18H16V16H18V14H20Z"
      fill="currentColor"
    />
  </svg>
);
const home = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2H22V20V22H2V20V14H4V20H8V16H10V20H14V14H16V20H20V4H10V6H8V2H10H20ZM12 12H14V14H12V12ZM10 10H12V12H10V10ZM8 10V8H10V10H8ZM6 12V10H8V12H6ZM6 12H4V14H6V12ZM16 6H18V8H16V6ZM14 6H12V8H14V6ZM16 10H18V12H16V10Z"
      fill="currentColor"
    />
  </svg>
);
const hidden = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 9H0V11H2H4V13H2V15H4V13H6H8V15V17H10V15H12H14V17H16V15V13H18H20V15H22V13H20V11H22H24V9H22H20V11H18H16V13H14H12H10H8V11H6H4V9H2Z"
      fill="currentColor"
    />
  </svg>
);
const image = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 9H8V11H6V9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0002 9H16.0002V11L18.0001 11V15H16.0002V17H12.0002V15H16.0002V11H12.0002V9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 11L12.0002 11V15H10V11Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3H8V5H4V7H2V19H4V21H20V19H22V7H20V5H16V3ZM16 5V7H20V19H4V7H8V5H16Z"
      fill="currentColor"
    />
  </svg>
);
const info = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 21H5V19H3V5H5V3H19V5H21V19H19V21ZM19 19V5H5V19H19ZM11 17H13V11H11V17ZM13 9H11V7H13V9Z"
      fill="currentColor"
    />
  </svg>
);
const ingest = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 4.25H14.5V6.25H16.5V8.25H20.5V10.25H22.5V12.25H24.5V18.25H22.5V20.25H17.5V18.25H22.5V12.25H20.5V10.25H16.5V8.25H14.5V6.25H8.5V8.25H6.5V10.25V12.25H4.5H2.5V18.25H7.5V20.25H2.5V18.25H0.5V12.25H2.5V10.25H4.5V8.25H6.5V6.25H8.5V4.25ZM11.5 20.25H13.5V18.25H15.5V16.25H13.5V9.25H11.5V16.25H9.5V18.25H11.5V20.25ZM9.5 16.25V14.25H7.5V16.25H9.5ZM15.5 16.25H17.5V14.25H15.5V16.25Z"
      fill="currentColor"
    />
  </svg>
);
const intelligence = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2H8V4H4V6H2V8H0V12H2V14H4V16H8V18H14V20H16V22H18H20V20H22V18H20V16H22V14H24V6H22V4H20V2ZM20 6V4H18V6H16V4H14V6H12V4H8V6H4V8H2V12H4V14H6V12H8V14V16H14V14H16V16H18H20V14H22V12H20V10H22V6H20ZM18 8V6H20V8H18ZM16 10H18V8H16V6H14V8H16V10ZM16 12V10H14H12V8H10V6H8V8H6H4V10H6H8V12H10V14H12V12H14H16ZM16 12V14H18H20V12H18H16ZM10 10H12V12H10V10ZM10 10H8V8H10V10ZM18 18H20V20H18H16V18H18Z"
      fill="currentColor"
    />
  </svg>
);
const keys = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5H4V7H10V5ZM20 9V7H10V9H20ZM20 15H22V9H20V15ZM18 15V17H20V15H18ZM16 15H18V13H16V15ZM14 15V17H16V15H14ZM12 15H14V13H12V15ZM10 17H12V15H10V17ZM4 17H2V7H4V17ZM4 17H10V19H4V17ZM6 11H8V13H6V11Z"
      fill="currentColor"
    />
  </svg>
);
const lightmode = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 0H11V4H13V0ZM0 11V13H4V11H0ZM24 11V13H20V11H24ZM13 24H11V20H13V24ZM8 6H16V8H8V6ZM6 8H8V16H6V8ZM8 18V16H16V18H8ZM18 16H16V8H18V16ZM20 2H22V4H20V2ZM20 4V6H18V4H20ZM22 22H20V20H22V22ZM20 20H18V18H20V20ZM4 2H2V4H4V6H6V4H4V2ZM2 22H4V20H6V18H4V20H2V22Z"
      fill="currentColor"
    />
  </svg>
);
const logs = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 4H21V6H19V4ZM17 8V6H19V8H17ZM15 8H17V10H15V8ZM15 8H13V6H15V8ZM3 6H11V8H3V6ZM11 16H3V18H11V16ZM18 18V16H20V14H18V16H16V14H14V16H16V18H14V20H16V18H18ZM18 18V20H20V18H18Z"
      fill="currentColor"
    />
  </svg>
);
const loop = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3H15V5H9V3ZM7 7V5H9V7H7ZM5 9V7H7V9H5ZM5 9V11V13H3V11V9H5ZM17 7H15V5H17V7ZM19 9H17V7H19V9ZM19 15H21V13V11V9H19V11V13V15ZM17 17V15H19V17H17ZM15 19V17H17V19H15ZM9 19H15V21H9V19ZM9 19V17H11V15H5V17V21H7V19H9Z"
      fill="currentColor"
    />
  </svg>
);
const media = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3H7V9H9V7H11V9H13V7H17V13H15V15H17V17H19V15H21V17H23V3H21V5H19V3H17V5H13V3H11V5H9V3ZM21 13H19V11H21V13ZM21 9V7H19V9H21Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9H15V21H9V15H13V13H7V21H1V15H5V9ZM11 19H13V17H11V19ZM5 17V19H3V17H5Z"
      fill="currentColor"
    />
  </svg>
);
const merge = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2H2V10H5V22H7V10H10V12H12V14H14V22H22V14H14V12H12V10H10V2ZM4 8V4H8V8H4ZM16 20V16H20V20H16Z"
      fill="currentColor"
    />
  </svg>
);
const menu = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 7H11H13H15H17H19V9H17H15H13H11H9H7H5V7H7H9ZM13 11H11H9H7H5V13H7H9H11H13H15H17H19V11H17H15H13ZM13 15H15H17H19V17H17H15H13H11H9H7H5V15H7H9H11H13Z"
      fill="currentColor"
    />
  </svg>
);
const metadata = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3H7V5H5V3ZM3 11V9V7V5H5V7V9V11H3ZM3 13H1V11H3V13ZM5 19H3V17V15V13H5V15V17V19ZM5 19H7V21H5V19ZM23 13H21V11H23V13ZM19 5L21 5V7V9L21 11L19 11L19 9V7V5ZM19 5L17 5V3H19V5ZM19 19H21V17V15V13H19V15V17V19ZM19 19V21H17V19L19 19ZM9 15H7V17H9V15ZM11 15H13V17H11V15ZM17 15H15V17H17V15Z"
      fill="currentColor"
    />
  </svg>
);
const microphone = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 1H9V3H7V5H9V3H11V5H9V7H7V5H5V17H7V19H11V21H7V23H17V21H13V19H17V17H19V5H17V3H15V1ZM15 5V3H13V5H11V7H9V9H7V17H17V9H15V7H17V5H15ZM13 7V5H15V7H13ZM13 7V9H15V11H9V9H11V7H13ZM13 13H11V15H13V13Z"
      fill="currentColor"
    />
  </svg>
);
const minus = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 11H20V13H4V11Z"
      fill="currentColor"
    />
  </svg>
);
const next = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2H10V4H12V6H14V8H16V10H18V12V14H16V16H14V18H12V20H10V22H8V20H6V18H8V16H10V14H12V12V10H10V8H8V6H6V4H8V2Z"
      fill="currentColor"
    />
  </svg>
);
const notification = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2H14V4H10V2ZM7 4V5.99999H5.00024V12H7.00024V6H17V4H7ZM5 16L5 12H3V16H5L5 18H19V16H5ZM9.99981 18V20H7.99981V18H9.99981ZM10.0001 20V22H14.0001V20H15.9999V18H13.9999V20H10.0001ZM19 16H21V12H19V16ZM19.0001 5.99999H17.0001V12H19.0001V5.99999Z"
      fill="currentColor"
    />
  </svg>
);
const nsfw = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 1H11V3H9V5H7V7H5V9H3V11H1V13H3V15H5V17H7V19H9V21H11V23H13V21H15V19H17V17H19V15H21V13H23V11H21V9H19V7H17V5H15V3H13V1ZM13 3V5H15V7H17V9H19V11H21V13H19V15H17V17H15V19H13V21H11V19H9V17H7V15H5V13H3V11H5V9H7V7H9V5H11V3H13ZM13 7H11V13H13V7ZM13 15H11V17H13V15Z"
      fill="currentColor"
    />
  </svg>
);
const objects = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7H16V9H12V7ZM10 11V9H12V11H10ZM10 15H8V11H10V15ZM12 17H10V15H12V17ZM16 17V19H12V17H16ZM18 15H16V17H18V19H20V21H22V19H20V17H18V15ZM18 11H20V15H18V11ZM18 11V9H16V11H18Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 3H4V5H2V19H4V21H10V19H4V5H20V7H22V5H20V3Z"
      fill="currentColor"
    />
  </svg>
);
const open = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 11V5V3H19H13V5H17V7H15V9H13V11H11V13H9V15H11V13H13V11H15V9H17V7H19V11H21ZM11 5H5V7H3V19H5V21H17V19H19V13H17V19H5V7H11V5Z"
      fill="currentColor"
    />
  </svg>
);
const organisation = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2H14V4H4V2ZM6 20H4V4H2V22H4H14H16H20H22V8H20V6H16V4H14V20H12V18H6V20ZM20 8H16V10H18V12H16V14H18V16H16V18H18V20H20V8ZM6 6H8V8H6V6ZM12 6H10V8H12V6ZM6 10H8V12H6V10ZM12 10H10V12H12V10ZM6 14H8V16H6V14ZM12 14H10V16H12V14Z"
      fill="currentColor"
    />
  </svg>
);
const outline = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 3H4V5H2V19H4V21H20V19H22V5H20V3ZM20 5V19H4V5H20ZM8 7H6V9H8V7ZM10 7H18V9H10V7ZM8 11H6V13H8V11ZM10 11H18V13H10V11ZM8 15H6V17H8V15ZM10 15H18V17H10V15Z"
      fill="currentColor"
    />
  </svg>
);
const pause = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3H9V5H7V3ZM7 19H5V5H7V19ZM9 19V21H7V19H9ZM9 19H11V5H9V19ZM15 5H13V19H15V21H17V19H19V5H17V3H15V5ZM15 5H17V19H15V5Z"
      fill="currentColor"
    />
  </svg>
);
const payment = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4H20V6H4V4ZM20 8V6H22V18H20V12H4V18H2V6H4V8H20ZM4 18H20V20H4V18Z"
      fill="currentColor"
    />
  </svg>
);
const play = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 3H9V5H7V19H9V21H11V19H13V17H15V15H17V13H19V11H17V9H15V7H13V5H11V3ZM11 5V7H13V9H15V11H17V13H15V15H13V17H11V19H9V5H11Z"
      fill="currentColor"
    />
  </svg>
);
const player = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 4H3V6H1V18H3V20H21V18H23V6H21V4ZM21 6V18H3V6H21ZM10 9H12V11H14V13H12V15H10V13V11V9Z"
      fill="currentColor"
    />
  </svg>
);
const plus = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6H10V10H6V12V14H10V18H12H14V14H18V12V10H14V6H12Z"
      fill="currentColor"
    />
  </svg>
);
const pricing = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // d="M13 1H11V3H7V5H5V11H7V13H11V19H7V17H5V19H7V21H11V23H13V21H17V19H19V13H17V11H13V5H17V7H19V5H17V3H13V1ZM17 13H13V19H17V13ZM7 11V5H11V11H7Z"
      // d="M9 3H11V5H13V3H15V5H17V7H15V11H17V13H15V17H17V19H15V21H13V19H11V21H9V19H7V17H9V13H7V11H9V7H7V5H9V3ZM7 7V11H5V7H7ZM7 17H5V15H7V17ZM17 17V13H19V17H17ZM17 7H19V9H17V7ZM11 17H13V13H11V17ZM13 11H11V7H13V11Z"
      d="M9 2H11V4H13V2H15V4H18V6H15V11H18V13H15V18H18V20H15V22H13V20H11V22H9V20H6V18H9V13H6V11H9V6H6V4H9V2ZM6 6V11H4V6H6ZM6 18H4V16H6V18ZM18 18H20V13H18V18ZM18 6H20V8H18V6ZM13 6V11H11V6H13ZM11 18V13H13V18H11Z"
      fill="currentColor"
    />
  </svg>
);
const project = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5V3H14V5H16V7H20V9H22V19H20V21H4V19H2V9H4V7H8V5H10ZM10 7H14V5H10V7ZM4 9V19H20V9H4Z"
      fill="currentColor"
    />
  </svg>
);
const quickstart = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99976 2.00003H1.99976V4.00003H3.99963V6.00003H5.99963L5.99963 8.00003H7.99963V6.00003H5.99963V4.00003H3.99976V2.00003ZM19.9995 2.00003H17.9995V4.00003H15.9996V6.00003H13.9996V8.00003H15.9996V6.00003H17.9996V4.00003H19.9995V2.00003ZM10 4H12V8H10V4ZM9.99988 14H11.9999V18H9.99988V14ZM18 12V10H14V12H18ZM8 10V12H4V10H8ZM17.9995 16H15.9995V14H17.9995V16ZM17.9995 18V16H19.9995V18H17.9995ZM15.9995 18H17.9995V20H15.9995V18ZM15.9995 18V16H13.9995V18H15.9995ZM13.9995 22H11.9995V20H13.9995V22ZM19.9995 14H21.9995V12H19.9995V14ZM3.99976 18H5.99963V16H3.99963V18H1.99976V20H3.99976V18ZM7.99963 16H5.99963V14H7.99963V16Z"
      fill="currentColor"
    />
  </svg>
);
const quote = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 4H17V8H13V12V16V20H17H21V16V12H17V8H21V4ZM7 8H3V12V16V20H7H11V16V12H7V8ZM7 8H11V4H7V8Z"
      fill="currentColor"
    />
  </svg>
);
const resize = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0001 3H21H19.0001H13V5L17.0001 5V7H19.0001V11H21.0001V3ZM16.9999 7H14.9999V9L13 9V11H15V9L16.9999 9V7ZM9.00012 15H11.0001V13H9.00006V15H7.00012V17H9.00012V15ZM5 13V17H6.99988V19H5V19H11.0001V21H5H3.00006H3V13H5Z"
      fill="currentColor"
    />
  </svg>
);
const rocket = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1H12H14V3H12H10V1ZM8 7V5V3H10V5V7H8ZM16 7V9V11V13V15V17H14H12H10H8V15V13V11V9V7H6V9V11H4V13H2V15V17V19V21V23H4V21H6V19H8V21H10V23H12H14V21H16V19H18V21H20V23H22V21V19V17V15V13H20V11H18V9V7H16ZM16 7V5V3H14V5V7H16ZM20 13H18V15V17V19H20V17V15V13ZM14 21H12H10V19H12H14V21ZM6 13V15V17V19H4V17V15V13H6ZM10 9H12H14V11V13H12H10V11V9Z"
      fill="currentColor"
    />
  </svg>
);
const safe = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2H20V4H4V2ZM4 20H2V4H4V20ZM20 20V22H4V20H20ZM20 20H22V4H20V20ZM8 8H6V10H8V8ZM16 8V6H14H12H10H8V8H10H12H14H16ZM18 12V10V8H16V10V12V14V16H14H12H10H8V14H6V16H8V18H10H12H14H16V16H18V14V12ZM14 12H12V14H14V12Z"
      fill="currentColor"
    />
  </svg>
);
const search = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2H6V4H4V6H2V12H4V14H6V16H12V14H14V16V18H16V20H18V22H20H22V20V18H20V16H18V14H16H14V12H16V6H14V4H12V2ZM12 4V6H14V12H12V14H6V12H4V6H6V4H12Z"
      fill="currentColor"
    />
  </svg>
);
const security = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 2H10V4H8V8H6V10H4V20H6V22H18V20H20V10H18V8H16V4H14V2ZM14 4V8H10V4H14ZM18 10V20H6V10H18ZM13 12H11V16H13V12Z"
      fill="currentColor"
    />
  </svg>
);
const sentiment = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2H10V6H8V8H6V10H2V12H6V10H8V8H10V6H12V4H14V8H12V10H20V12H16V14H20V16H16V18H20V20H6V18H2V20H6V22H20V20H22V18V10H20V8H16V4V2H12Z"
      fill="currentColor"
    />
  </svg>
);
const sequences = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 22L22 22L22 10L20 10L20 20L10 20L10 22L20 22ZM18 18L16 18L6 18L6 16L16 16L16 6L18 6L18 18ZM14 14L12 14L4 14L2 14L2 2L4 2L12 2L14 2L14 14ZM4 4L4 12L12 12L12 4L4 4Z"
      fill="currentColor"
    />
  </svg>
);
const settings = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3H7V5H3V7H7V9H9V7H21V5H9V3ZM9 5V7H7V5H9ZM3 11V13H15V15H17V13H21V11H17V9H15V11H3ZM15 11H17V13H15V11ZM7 17H21V19H7V17ZM5 17V15H7V17H5ZM5 19H3V17H5V19ZM5 19V21H7V19H5Z"
      fill="currentColor"
    />
  </svg>
);
const sidebar = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 5H22V19H2V5ZM4 7V17H6V7H4ZM8 7V17H20V7H8Z"
      fill="currentColor"
    />
  </svg>
);
const sidebar_locked = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 5H22V19H2V5ZM8 7V17H20V7H8Z"
      fill="currentColor"
    />
  </svg>
);
const sort = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7L3 9L5 9L5 7L7 7L7 17L7 19L9 19L9 17L9 7L11 7L11 9L13 9L13 7L11 7L11 5L9 5L9 3L7 3L7 5L5 5L5 7L3 7ZM19 19L19 17L21 17L21 15L19 15L19 17L17 17L17 7L17 5L15 5L15 7L15 17L13 17L13 15L11 15L11 17L13 17L13 19L15 19L15 21L17 21L17 19L19 19Z"
      fill="currentColor"
    />
  </svg>
);
const sources = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 6H21V19H23V6ZM19 21H6V23H19V21Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 1H3V3H1V17H3V19H17V17H19V3H17V1ZM17 3V17H3V3H17ZM11 7H13V9H11V7ZM9 11V9H11V11H9ZM7 13V11H9V13H7ZM7 13V15H5V13H7ZM13 9H15V11H13V9ZM7 5H5V7H7V5Z"
      fill="currentColor"
    />
  </svg>
);
const source = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3H20V5H4V3ZM4 19H2V5H4V19ZM20 19V21H4V19H20ZM20 19H22V5H20V19ZM14 9H12V11H10V13H8V15H6V17H8V15H10V13H12V11H14V13H16V15H18V13H16V11H14V9ZM8 7H6V9H8V7Z"
      fill="currentColor"
    />
  </svg>
);
const speech = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3H18V5H6V3ZM4 7V5H6V7H4ZM4 13H2V7H4V13ZM6 15H4V13H6V15ZM8 19H6V15H8V19ZM10 19V21H8V19H10ZM12 17H10V19H12V17ZM18 15V17H12V15H18ZM20 13V15H18V13H20ZM20 7H22V13H20V7ZM20 7V5H18V7H20Z"
      fill="currentColor"
    />
  </svg>
);
const stats = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3H5V19H21V21H5H3V3ZM9 11H7V17H9V11ZM11 9H13V17H11V9ZM17 5H15V17H17V5Z"
      fill="currentColor"
    />
  </svg>
);
const subtitles = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 4H3V6H1V18H3V20H21V18H23V6H21V4ZM21 6V18H3V6H21ZM7 14V16H17V14H19V12H5V14H7Z"
      fill="currentColor"
    />
  </svg>
);
const success = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3H19V5H5V3ZM5 19H3V5H5V19ZM19 19V21H5V19H19ZM19 19H21V5H19V19ZM9 12H7V14H9V16H11V14H13V12H15V10H17V8H15V10H13V12H11V14H9V12Z"
      fill="currentColor"
    />
  </svg>
);
const summary = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2H10V4H8V8H4V10H2V12H4L4 14H8L8 18H10L10 20H12L12 18H14L14 14H18V12H20V10H18V8H14V4H12V2ZM12 4V8H14V10H18V12H14V14H12V18H10L10 14H8V12L4 12V10H8V8H10V4H12ZM4 16H2V18H4V16ZM16 2H18V4H16V2ZM20 18H22V20H20V22H18V20H16V18H18V16H20V18Z"
      fill="currentColor"
    />
  </svg>
);
const support = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2H19V4H21V20H19V4H5V18H17V20H18.9998V22H16.9998V20H5V18H3V4H5V2ZM11 14H13V16H11V14ZM13 10H11V12H13V10ZM15 8L15 10H13V8H9V6H15V8Z"
      fill="currentColor"
    />
  </svg>
);
const tags = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3H13V5H5V13H3V5V3ZM7 15H5V13H7V15ZM9 17H7V15H9V17ZM11 19V17H9V19H11ZM13 19H11V21H13H15V19H17V17H19V15H21V13V11H19V9H17V7H15V5H13V7H15V9H17V11H19V13V15H17V17H15V19H13ZM9 7H7V9H9V7Z"
      fill="currentColor"
    />
  </svg>
);
const tasks = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 22H19V20V16H17V18H15V16H13V14H15V10H17V8H15H9H7V10H9V14H11V16H9V18H7V16H5V20V22ZM7 16H9V14H7V16ZM7 8V4H17V8H19V4V2H5V4V8H7ZM15 14V16H17V14H15Z"
      fill="currentColor"
    />
  </svg>
);
const team = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2H5V4H3.00024V10H5V12H11V10H5.00024V4H11V2ZM11.0002 4H13.0002V10H11.0002V4ZM0 16H2V20H14V22H2H0V16ZM2 16H14V14H2V16ZM16.0002 16H14.0002V22H16.0002V16ZM15 2H19V4H15V2ZM19 10H15V12H19V10ZM19.0002 4H21.0002V10H19.0002V4ZM24.0002 16H22.0002V20H18V22H24V20H24.0002V16ZM18 14H22V16H18V14Z"
      fill="currentColor"
    />
  </svg>
);
const terminal = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3H2V21H4H20H22V3H20H4ZM20 5V19H4V5H20ZM10 9H8V7H6V9H8V11H10V13H8V15H6V17H8V15H10V13H12V11H10V9Z"
      fill="currentColor"
    />
  </svg>
);
const text = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7H21V9H3V7ZM3 11H21V13H3V11ZM15 15H3V17H15V15Z"
      fill="currentColor"
    />
  </svg>
);
const thumbnails = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4H21V6H3V4ZM21 14V6H23V18H21V16H19V18H21V20H3V18H5V16H3V18H1V6H3V14H21ZM15 16H17V18H15V16ZM13 16H11V18H13V16ZM7 16H9V18H7V16Z"
      fill="currentColor"
    />
  </svg>
);
const track = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 2H8V4H6.00001V6H2V8H0V20H2V22H4V20H16V18H18V8H16V6H8V4H22V16H20L20 14H18V16H20V18H22L22 16H24V4H22V2ZM16 8V18H4V20H2V8H16ZM4 12H6V14H4V12ZM10 12H8V14H10V12ZM12 12H14V14H12V12Z"
      fill="currentColor"
    />
  </svg>
);
const tracks = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 2H8V4H6.00001V6H2V8H0V20H2V22H4V20H16V18H18V8H16V6H8V4H22V16H20L20 14H18V16H20V18H22L22 16H24V4H22V2ZM16 8V18H4V20H2V8H16ZM4 12H6V14H4V12ZM10 12H8V14H10V12ZM12 12H14V14H12V12Z"
      fill="currentColor"
    />
  </svg>
);
const transform = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2H7V4H9V6H5V8H3V18H5V20H7V18H5V8H9V10H7V12H9V10H11V8H13V6H11V4H9V2ZM17 4H19V6H17V4ZM19 16V6H21V16H19ZM15 20V18H19V16H15V14H17V12H15V14H13V16H11V18H13V20H15ZM15 20H17V22H15V20Z"
      fill="currentColor"
    />
  </svg>
);
const trash = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9995 2H15.9998V4H15.9995V6H18H20H21.9995V8H20V20H18V8L15.9995 8H13.9995H9.99976H7.99976L6 8V20H4V8H1.99951V6H4H6H7.99976V4V2H9.99976H13.9995H15.9995ZM6 20H18V22H6V20ZM13.9995 4H9.99976V6H13.9995V4ZM8.99951 10H10.9995V18H8.99951V10ZM14.9998 10H12.9998V18H14.9998V10Z"
      fill="currentColor"
    />
  </svg>
);
const upload = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4H10V6H8V8H4V10H2V12H0V18H2V20H9V18H2V12H4V10H8V8H10V6H16V8H18V10V12H20H22V18H15V20H22V18H24V12H22V10H20V8H18V6H16V4ZM13 9H11V11H9V13H7V15H9V13H11V20H13V13H15V15H17V13H15V11H13V9Z"
      fill="currentColor"
    />
  </svg>
);
const video = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3H2V21H4V19H6V21H8V19H16V21H18V19H20V21H22V3H20V5H18V3H16V5H8V3H6V5H4V3ZM6 17V15H4V17H6ZM20 17H18V15H20V17ZM6 13V11H4V13H6ZM20 13H18V11H20V13ZM6 9V7H4V9H6ZM16 17H8V7H16V17ZM20 7V9H18V7H20Z"
      fill="currentColor"
    />
  </svg>
);
const visible = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 5H16V7H8V5ZM4 9V7H8V9H4ZM2 11V9H4V11H2ZM2 13V11H0V13H2ZM4 15H2V13H4V15ZM8 17H4V15H8V17ZM16 17V19H8V17H16ZM20 15V17H16V15H20ZM22 13V15H20V13H22ZM22 11H24V13H22V11ZM20 9H22V11H20V9ZM20 9V7H16V9H20ZM9 9H15V11H17V13H15V15H9V13H7V11H9V9Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
    />
  </svg>
);
const warning = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3H5V5H3V19H5V21H19V19H21V5H19V3ZM19 5V19H5V5H19ZM11 7H13V13H11V7ZM13 15H11V17H13V15Z"
      fill="currentColor"
    />
  </svg>
);
const workflows = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3H15V5H19V3ZM19 9H21V5H19V9ZM15 9V11H19V9H15ZM5 5V3H9H11V6H13V5H15V9H13V8H11V11H9H8V16H6V11H5H3V5H5ZM5 5H9V9H5V5ZM13 16H8V18H13V19H15V15H13V16ZM19 15V13H15V15H19ZM19 19H21V15H19V19ZM19 19V21H15V19H19Z"
      fill="currentColor"
    />
  </svg>
);
const webhooks = (className, title) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    title={title || ''}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 3H15V5H17V7H7H5V9H7H17V11H15V13H17V11H19V9H21V7H19V5H17V3ZM5 19H7V21H9V19H7V17H17H19V15H17H7V13H9V11H7V13H5V15H3V17H5V19Z"
      fill="currentColor"
    />
  </svg>
);

export const icons = {
  ittybit,
  account,
  add,
  arrow_down,
  arrow_left,
  arrow_right,
  arrow_up,
  attach,
  audio,
  automation,
  back,
  cdn,
  chapters,
  check,
  chevron_left,
  chevron_right,
  click,
  close,
  cross,
  code,
  compress,
  copy,
  darkmode,
  dashboard,
  description,
  docs,
  domains,
  dot,
  edit,
  education,
  ellipsis,
  email,
  error,
  expand,
  expand_up,
  faces,
  file,
  files,
  filter,
  folder,
  guides,
  hidden,
  help,
  home,
  image,
  info,
  ingest,
  intelligence,
  keys,
  lightmode,
  logs,
  loop,
  media,
  merge,
  menu,
  metadata,
  microphone,
  minus,
  next,
  notification,
  nsfw,
  objects,
  open,
  organisation,
  outline,
  pause,
  payment,
  play,
  player,
  plus,
  pricing,
  project,
  prompt: summary,
  quickstart,
  quote,
  resize,
  rocket,
  safe,
  search,
  security,
  sentiment,
  sequences,
  settings,
  sidebar,
  sidebar_locked,
  sort,
  source,
  sources,
  speech,
  stats,
  subtitles,
  success,
  summary,
  support,
  tags,
  tasks,
  team,
  terminal,
  text,
  thumbnails,
  track,
  tracks,
  transform,
  trash,
  upload,
  video,
  visible,
  warning,
  workflow: workflows,
  workflows,
  webhook: webhooks,
  webhooks,
};
